/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
$small-font: 12px;

/*
TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.
*/
mat-card.basecard {
  margin: 20px;
}

span.small {
  font-size: $small-font;
}

button.small {
  font-size: $small-font;
  margin-left: 1px;
  margin-right: 1px;
  vertical-align: middle;
  line-height: 14px;
  min-width: 10px;
  padding: 2px;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }

  mat-icon {
    font-size: $small-font;
    vertical-align: middle;
    width: 14px;
    height: 14px;
  }
}

button.normal {
  border-radius: 24px;
  padding-left: 5px;
  margin-left: 3px;
  margin-right: 3px;
  line-height: 30px;

  :hover {
    opacity: .75;
  }
}

input {
  width: 100%;
}

mat-form-field {
  width: 100%;
}

/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
.mat-form-field-wrapper {
  margin-left: 5px !important;
  margin-right: 5px !important;
}